import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { BulkOrderService } from '../../services/bulk-order-service';
import { StorageService } from '../../../../SGRE-shared/services/storage.service';
import { Router } from '@angular/router';
import { CartService } from '../../../cart/services/cart.service';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { AppConstants } from '../../../../SGRE-shared/constants/app-constant';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-next-button',
  templateUrl: './next-button.component.html',
  styleUrl: './next-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NextButtonComponent {
  @Output() dataEvent = new EventEmitter<string>();
  isSelectedChoose: string = 'choosefile';
  currentpage$: Observable<number>;
  nextPage$: Observable<number>;
  isError: boolean = false;
  public userRoles: any[];
  role: string;
  validFile: any;
  counter: number = 1;
  isSelectedNew: string;
  newfoundArray: any[] = [];
  newArray: any[] = [];
  notFound: any[] = [];
  display: string = 'none';
  notFoundData: any[] = [];
  private unsubscribe$ = new Subject<void>();

  constructor(
    public bulkservice: BulkOrderService,
    private router: Router,
    private readonly changeRef: ChangeDetectorRef,
    public storageService: StorageService,
    public cartService: CartService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.notFound = [];
    this.newfoundArray = [];
    this.changeRef.markForCheck();
    this.currentpage$ = this.bulkservice.isNext$;
    this.currentpage$.pipe(takeUntil(this.unsubscribe$)).subscribe(count => {
      this.counter = count;
      this.changeRef.markForCheck();
    });
    this.bulkservice.isUploadFile$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      (title) => (this.validFile = title)
    );
    this.getUserRole();
    this.bulkservice.countSubject.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data) {
        this.counter = 1;
      }
    });
  }

  getUserRole() {
    let userRole: any = '';
    userRole = this.storageService.userRoles;

    if (userRole?.includes('Requester') && !userRole?.includes('Purchaser')) {
      this.role = 'Requester';
    } else if (
      userRole?.includes('Purchaser') &&
      !userRole?.includes('Requester')
    ) {
      this.role = 'Purchaser';
    } else if (
      userRole?.includes('Purchaser') &&
      userRole?.includes('Requester')
    ) {
      this.role = 'Purchaser&Requester';
    }
  }

  decrement() {
    return this.counter > 1 ? this.counter - 1 : this.counter;
  }

  increment() {
    if (this.counter < 4) {
      this.counter = this.counter + 1;
      return this.counter;
    } else {
      return this.counter;
    }
  }

  onNext() {
    if (this.counter === 1 || this.counter === 3 ||
      (this.counter === 2 && this.bulkservice.getUploadFileArrayLength() &&
        this.bulkservice.excelDataSubject.getValue().length >= 1 && !this.validFile)
    ) {
      this.isError = false;
      let arrayvalue = this.bulkservice.excelDataSubject.getValue();
      this.bulkservice.tableDataSubject
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((req) => {
          const data = req;
          this.notFound = data?.notFoundProductCodes;
          this.newfoundArray = arrayvalue
            .map((aItem: any) => {
              let match = data?.foundProductCodes?.find(
                (mItem) => mItem?.code === aItem?.partNumber
              );
              if (match) {
                return {
                  productCode: aItem?.partNumber + '_N',
                  count: this.checkQuantity(
                    aItem?.quantity,
                    match?.maxQuantity
                  ),
                };
              }
              return null;
            })
            .filter((aItem) => aItem !== null);
          this.bulkservice.isPartNumber.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
            if (data.formArray?.length && this.newfoundArray?.length) {
              const SecondArrayPartNum = data.formArray[data.index].partNumber;
              this.newfoundArray = this.newfoundArray.map((item) => {
                if (data.formArray[data.index][data.colName]) {
                  if (
                    SecondArrayPartNum.includes(item.productCode.replace(/(_R|_N)$/, ''))
                  ) {
                    return {
                      ...item,
                      productCode: item.productCode.replace(/(_R|_N)$/, '_R'),
                    };
                  }
                } else {
                  if (SecondArrayPartNum.includes(item.productCode.replace(/(_R|_N)$/, ''))) {
                    return {
                      ...item,
                      productCode: item.productCode.replace(/(_R|_N)$/, '_N'),
                    };
                  }
                }
                return item;
              });
            }
          });
        });
      if (this.counter === 3 && !this.bulkservice.cartNameSubject.getValue().length
        && this.newfoundArray?.length > 0) {
        this.bulkservice.errorSubject.next(true);
        return;
      } else {
        let count = this.increment();
        this.bulkservice.setNext(count);
        if (count === 4) {
          let requestObj
          this.bulkservice.tableDataSubjectUpdate.pipe(takeUntil(this.unsubscribe$)).subscribe((req) => {
            const data = req;
            data.map(obj => {
              if (!obj.refurbished && obj.refurbishedUpdate) {
                requestObj = {
                  productCode: obj.partNumber + '_R',
                  count: JSON.stringify(obj.quantity)
                };
              } else {
                requestObj = {
                  productCode: obj.partNumber + '_N',
                  count: JSON.stringify(obj.quantity)
                };
              }
              this.newArray.push(requestObj);
            })
          });
          this.globalService.loadingSubject.next(true);
          let SaveBulkOrder = {
            foundProductCodes: this.newArray,
            notFoundProductCodes: this.notFound,
          };
          let cartName: string = this.bulkservice.cartNameSubject.getValue();
          let encodedCart = encodeURIComponent(cartName)
          this.bulkservice.getCartBulkOrder(SaveBulkOrder, encodedCart)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
              next: (refData) => {
                if (refData?.body?.code) {
                  this.storageService.cartId = refData?.code;
                  this.globalService.getCartsListByLegalEntity();
                  this.globalService.loadingSubject.next(false);
                  this.display = 'block';
                  this.changeRef.markForCheck();
                }
              },
              error: (error) => (
                this.globalService.loadingSubject.next(false)
              ),
            });
        }
      }
      this.bulkservice.getUploadFileArrayLength();
      if (this.counter === 3) {
        this.getBulkOrder();
      }
    } else {
      this.isError = true;
    }
  }

  checkQuantity(quantity, maxQuantity?) {
    if (!!maxQuantity) {
      if (maxQuantity <= 1000) {
        if (quantity < maxQuantity) {
          return quantity;
        } else {
          return maxQuantity;
        }
      } else {
        return 1000;
      }
    } else {
      return quantity > 1000 ? 1000 : quantity;
    }
  }

  getBulkOrder() {
    this.globalService.loadingSubject.next(true);
    this.bulkservice.getBulkOrder().pipe(takeUntil(this.unsubscribe$)).subscribe((refData) => {
      this.globalService.loadingSubject.next(false);
      if (refData) {
        this.bulkservice.tableDataSubject.next(refData);
      }
    });
  }

  onCancel() {
    this.isError = false;
    let count = 1;
    this.bulkservice.setNext(count);
    this.router.navigateByUrl(AppConstants.routeUrls.home);
  }

  goNext() {
    this.display = 'none';
    if (this.bulkservice.cartNameSubject.getValue().length) {
      this.router.navigate(['cart']);
    }
    else {
      this.router.navigate([AppConstants.routeUrls.home]);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.bulkservice.errorSubject.next(false);
    this.unsubscribe$.complete();
    this.bulkservice.setUploadFileName('-');
    this.globalService.clearMessagesOnDestroy();
  }

}
